import { WhatsAppOutlined } from "@ant-design/icons";
import { Button, Descriptions, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WhatsappShareButton } from "react-share";

const CreateUserModal = ({
  newUserDetails,
  isModalOpen,
  setIsModalOpen,
  type,
}) => {
  // state
  const [copy, setCopy] = useState(false);
  const navigate = useNavigate();

  //variable
  const shareUrlString = `UserId: ${newUserDetails.userId},\n\n UserName: ${newUserDetails.accountName},\n\n AccountType: ${newUserDetails.accountType},\n\n Passward: ${newUserDetails.passward},\n\n WebLink: https://rupot.in/login,\n\n Android Link:${process.env.REACT_APP_ANDROID_LINK},\n\n Apple Link:${process.env.REACT_APP_APPLE_LINK} `;
  const title = "User created successful,\n\n";

  // function
  const handleOk = () => {
    setIsModalOpen(false);
    if (type === "rent") {
      navigate(`/users/accounts/${type}`);
    } else {
      navigate(`/users/accounts/`);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (type === "rent") {
      navigate(`/users/accounts/${type}`);
    } else {
      navigate(`/users/accounts`);
    }
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard?.writeText(`${title}${shareUrlString}`);
      setCopy(true);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  // data
  const items = [
    {
      key: "1",
      label: "User ID",
      children: `${newUserDetails?.userId}`,
    },
    {
      key: "2",
      label: "Username",
      children: `${newUserDetails?.accountName}`,
    },
    {
      key: "3",
      label: "User Passward",
      children: `${newUserDetails?.passward}`,
    },
    {
      key: "4",
      label: "Account Type",
      children: `${newUserDetails?.accountType}`,
    },
    {
      key: "5",
      label: "Website Link",
      children: (
        <a
          href="https://rupot.in/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          rupot.in
        </a>
      ),
    },
    {
      key: "6",
      label: "Android Link",
      children: (
        <a
          href={process.env.REACT_APP_ANDROID_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          rupot.in
        </a>
      ),
    },
    {
      key: "7",
      label: "Apple Link",
      children: (
        <a
          href={process.env.REACT_APP_APPLE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          rupot.in
        </a>
      ),
    },
    {
      key: "8",
      label: "Share with What's up",
      children: (
        <WhatsappShareButton url={shareUrlString} title={title}>
          <WhatsAppOutlined style={{ fontSize: "1.7rem" }} />
        </WhatsappShareButton>
      ),
    },
    {
      key: "9",
      label: "Copy Details",
      children: (
        <Button
          onClick={handleCopy}
          style={{
            textAlign: "center",
            fontSize: "1rem",
          }}
        >
          {copy ? "Copied!" : "Copy"}
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="Created User Info"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      <Descriptions items={items} style={{ marginTop: "2rem" }} />
    </Modal>
  );
};

export default CreateUserModal;
