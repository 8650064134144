import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home";
import Users from "./pages/Users";
import NewAccount from "./pages/Users/NewAccount";
import Accounts from "./pages/Users/Accounts";
import MainLayout from "./pages/MainLayout";
import MarketWatch from "./pages/MarketWatch";
import { ProtectedRoute } from "./components/ProtectedRoute";
import NotFound from "./components/NotFound";
import Navbar from "./components/NavBar";
import Header from "./components/Header";
import RootCommonFunction from "./components/RootCommonFunction";
import AppStyle from "./App.style";
import "./App.css";
import ScrollToTopButton from "./components/ScrollToTop";
import DashboardScreen from "./pages/Dashboard";
import SocketProvider from "./components/Socket";
import SummaryReport from "./pages/SummaryReport";
import TradingTrade from "./pages/TradingTrade";
import TradingBlocked from "./pages/TradingBlocked";
import TradingPosition from "./pages/TradingPosition";
import TradeLog from "./pages/TradeLog";
import RejectionLog from "./pages/RejectionLog";
import BlukTrading from "./pages/BulkTrading";
import BrokerageReport from "./pages/BrokerageReport";
import TradeReport from "./pages/TradeReport";
import ProfitLossReport from "./pages/ProfitLossReport";
import AccountPointLedger from "./pages/AccountPointLedger";
import AccountPointEntry from "./pages/AccountPointEntry";
import AccountPointJVEntry from "./pages/AccountPointJVEntry";
import { jwtDecode } from "jwt-decode";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { setAuth } from "./store/slice/authSlice";
import ExpirySetting from "./pages/ExpirySetting";
import ResetPassward from "./pages/ResetPassward/ResetPassward";
import { Row, Typography, theme } from "antd";
import MarketSetting from "./pages/MarketSetting/MarketSetting";
import Profile from "./pages/Profile/Profile";
import ReportCart from "./pages/SummaryReport/ReportCart";
import ScrollBar from "./components/NavBar/ScrollBar";
import UserLedger from "./pages/UserLedger/UserLedger";
import M2mAlertLog from "./pages/M2mAlertLog/M2mAlertLog";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PointLedgerLog from "./pages/PointLedgerLog";
import UserEditLog from "./pages/UserEditLog/UserEditLog";
import CreateRentAccount from "./pages/Users/RentAccount/CreateRentAccount";
import { useAuthChannel } from "./hooks/useAuthChannel";
import BrokerSharingReport from "./pages/BrokerSharingReport/BrokerSharingReport";

function App() {
  const dispatch = useDispatch();
  const { broadcastLogout } = useAuthChannel();
  //redux
  const accessToken = useSelector((state) => state.authSlice.accessToken);
  const loginUser = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const location = useLocation();

  const { exp } = useMemo(() => {
    if (accessToken) {
      return jwtDecode(accessToken);
    } else {
      return 0;
    }
  }, [accessToken]);

  useLayoutEffect(() => {
    let logoutTime = exp * 1000;
    if (!exp || Date.now() >= logoutTime) {
      dispatch(setAuth(null));
      return;
    }
  });

  useEffect(() => {
    function adjustTopProperty(e, isZero) {
      const stickyHeader = document.querySelector(".sticky-main-header");
      if (stickyHeader) {
        const headerHeight = stickyHeader.offsetHeight;

        const targetElements = document.querySelectorAll(
          ".ant-table-sticky-holder"
        );
        targetElements.forEach((targetElement) => {
          targetElement.style.top = `${
            isZero || document?.fullscreenElement ? 0 : headerHeight
          }px`;
        });
      }
    }
    window.addEventListener("load", adjustTopProperty);
    window.addEventListener("resize", adjustTopProperty);
    adjustTopProperty();
    document.addEventListener("fullscreenchange", (e) => {
      document?.fullscreenElement
        ? adjustTopProperty(e, true)
        : adjustTopProperty(e, false);
    });
  }, [location]);

  // useLayoutEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       window.location.reload();
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  return (
    <SocketProvider>
      <AppStyle.Wrapper>
        <AppStyle.CSSReset />
        <RootCommonFunction />
        {accessToken ? (
          <div className="sticky-main-header">
            <Header />
            <Navbar />
            <ScrollBar />
          </div>
        ) : null}
        {/* <Title /> */}
        <AppStyle.Main $fullHeight={!accessToken}>
          <ScrollToTopButton />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/resetPassward"
              element={
                // <ProtectedRoute>
                <ResetPassward />
                // </ProtectedRoute>
              }
            />

            {loginUser !== "broker" && loginUser !== "owner" && (
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
            )}

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    {/* <Home /> */}
                    <DashboardScreen />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    {/* <Home /> */}
                    <DashboardScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="market"
                element={
                  <ProtectedRoute>
                    <MarketWatch />
                  </ProtectedRoute>
                }
              />

              <Route
                path="report"
                element={
                  <ProtectedRoute>
                    <SummaryReport />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`valanBill/:combinedParams`}
                element={
                  <ProtectedRoute>
                    <ReportCart />
                  </ProtectedRoute>
                }
              />

              <Route
                path="trading"
                // element={
                //   <ProtectedRoute>
                //     <Home />
                //   </ProtectedRoute>
                // }
              />
              <Route
                path="trading/trade"
                element={
                  <ProtectedRoute>
                    <TradingTrade />
                  </ProtectedRoute>
                }
              />
              <Route
                path="trading/blocked-scripts"
                element={
                  <ProtectedRoute>
                    <TradingBlocked />
                  </ProtectedRoute>
                }
              />
              <Route
                path="trading/position"
                element={
                  <ProtectedRoute>
                    <TradingPosition />
                  </ProtectedRoute>
                }
              />
              {loginUser !== "customer" && (
                <Route
                  path="users"
                  element={
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="new-account"
                    key={"new-account"}
                    element={
                      <ProtectedRoute>
                        <NewAccount is_edits={false} key="new" />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    key={"edit-account"}
                    path={`edit-account/:id`}
                    element={
                      <ProtectedRoute>
                        <NewAccount is_edits={true} key={`edit-account`} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={`accounts/:type?`}
                    element={
                      <ProtectedRoute>
                        <Accounts />
                      </ProtectedRoute>
                    }
                  />
                  {loginUser !== "dealer" && (
                    <Route
                      path="create-rent-account"
                      element={
                        <ProtectedRoute>
                          <CreateRentAccount key={"createRentAccount"} />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {loginUser !== "dealer" && (
                    <Route
                      path={`edit-rent-account/:id`}
                      element={
                        <ProtectedRoute>
                          <CreateRentAccount
                            is_edits={true}
                            key={"editRentAccount"}
                          />
                        </ProtectedRoute>
                      }
                    />
                  )}
                </Route>
              )}
              {loginUser !== "customer" && (
                <Route
                  path={`user-ledger/:id`}
                  element={
                    <ProtectedRoute>
                      <UserLedger />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="utilities"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="utilities/trade-log"
                  element={
                    <ProtectedRoute>
                      <TradeLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="/utilities/user-edit-log"
                  element={
                    <ProtectedRoute>
                      <UserEditLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="utilities/point-ledger-log"
                  element={
                    <ProtectedRoute>
                      <PointLedgerLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* <Route
                path="utilities/cash-ledger-log"
                element={
                  <ProtectedRoute>
                    <CashLedgerLog />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="utilities/rejection-log"
                element={
                  <ProtectedRoute>
                    <RejectionLog />
                  </ProtectedRoute>
                }
              />
              {loginUser !== "customer" && (
                <Route
                  path="utilities/M2mAlert-log"
                  element={
                    <ProtectedRoute>
                      <M2mAlertLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="utilities/bulk-trading"
                  element={
                    <ProtectedRoute>
                      <BlukTrading />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="account-point-ledger"
                element={
                  <ProtectedRoute>
                    <AccountPointLedger />
                  </ProtectedRoute>
                }
              />
              {loginUser !== "customer" && (
                <Route
                  path="account-point-entry"
                  element={
                    <ProtectedRoute>
                      <AccountPointEntry />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="account-point-jv-entry"
                  element={
                    <ProtectedRoute>
                      <AccountPointJVEntry />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="reports/brokerage-report"
                  element={
                    <ProtectedRoute>
                      <BrokerageReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && loginUser !== "owner" && (
                <Route
                  path="reports/broker-sharing-report"
                  element={
                    <ProtectedRoute>
                      <BrokerSharingReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="reports/trade-report"
                  element={
                    <ProtectedRoute>
                      <TradeReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser !== "customer" && (
                <Route
                  path="reports/profit-and-loss-percentage"
                  element={
                    <ProtectedRoute>
                      <ProfitLossReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* <Route
                path="settings/script-block-allow"
                element={
                  <ProtectedRoute>
                    <ScriptBlockAllow />
                  </ProtectedRoute>
                }
              /> */}
              {/* <Route
                path="settings/notification"
                element={
                  <ProtectedRoute>
                    <NotificationPage />
                  </ProtectedRoute>
                }
              /> */}

              {/* {loginUser === "owner" && (
                <Route
                  path="settings/valan-setting"
                  element={
                    <ProtectedRoute>
                      <ValanSetting />
                    </ProtectedRoute>
                  }
                />
              )} */}

              {loginUser === "owner" && (
                <Route
                  path="settings/expiry-setting"
                  element={
                    <ProtectedRoute>
                      <ExpirySetting />
                    </ProtectedRoute>
                  }
                />
              )}
              {loginUser === "owner" && (
                <Route
                  path="settings/market-setting"
                  element={
                    <ProtectedRoute>
                      <MarketSetting />
                    </ProtectedRoute>
                  }
                />
              )}
            </Route>
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <NotFound />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AppStyle.Main>
        {/* scroll to top is not working  */}
      </AppStyle.Wrapper>
    </SocketProvider>
  );
}

export default App;

const Title = () => {
  const location = useLocation();
  const token = theme.useToken().token;

  const { hash, pathname, search } = location;
  const path = ["/dashboard", "/market", "/report", "/settings/notification"];
  const flage = path.includes(pathname);

  const modifiedString = pathname.replace(/\//g, ">");
  const parts = modifiedString.split(">");
  const capitalizedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1)
  );
  const result = capitalizedParts.join(">");
  const name = result.substring(1);

  return (
    <>
      {!flage && (
        <Row
          justify={"left"}
          style={{
            marginTop: "1rem",
            marginLeft: "4.5rem",
            // color: token.colorTextLightSolid,
          }}
        >
          <Typography.Title
            level={5}
            style={{ color: token.colorPrimaryHover }}
          >
            {name}
          </Typography.Title>
        </Row>
      )}
    </>
  );
};
